import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Forms from "../views/Forms.vue";
import Tables from "../views/Tables.vue";
import UIElements from "../views/UIElements.vue";
import Login from "../views/Login.vue";
import Modal from "../views/Modal.vue";
import Chart from "../views/ChartView.vue";
import Card from "../views/CardView.vue";
import Blank from "../views/BlankView.vue";
import NotFound from "../views/NotFound.vue";
import Caja from "../views/Caja.vue";
import Ordenes from "../views/Ordenes.vue";
import Clientes from "../views/Clientes.vue";
import Impresiones from "../views/Impresiones.vue";
import Egresos from "../views/Egresos.vue";
import Cartera from "../views/Cartera.vue"
import PrintOrder from "../views/PrintOrder.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { layout: "empty" },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/caja",
    name: "Caja",
    component: Caja,
  },
  {
    path: "/ordenes",
    name: "Ordenes",
    component: Ordenes,
  },
  {
    path: "/clientes",
    name: "Clientes",
    component: Clientes,
  },
  {
    path: "/impresiones",
    name: "Impresiones",
    component: Impresiones,
  },
  {
    path: "/egresos",
    name: "Egresos",
    component: Egresos,
  },
  {
    path: "/cartera",
    name: "Cartera",
    component: Cartera,
  },
  {
    path: "/forms",
    name: "Forms",
    component: Forms,
  },
  {
    path: "/cards",
    name: "Cards",
    component: Card,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/ui-elements",
    name: "UIElements",
    component: UIElements,
  },
  {
    path: "/modal",
    name: "Modal",
    component: Modal,
  },
  {
    path: "/charts",
    name: "Chart",
    component: Chart,
  },
  {
    path: "/blank",
    name: "Blank",
    component: Blank,
  },
  {
    path: "/printorder",
    name: "PrintOrder",
    component: PrintOrder,
  },
  { path: "/:pathMatch(.*)*", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
